import { ReactNode } from "react";
import { FloatingPosition } from "@mantine/core/lib/Floating";

export enum TooltipMode {
    CLICK = "click",
    HOVER = "hover",
}

export type TooltipProps = {
    message?: string | ReactNode;
    position?: FloatingPosition;
    svgComponent?: ReactNode;
    mode?: `${TooltipMode}`;
    testId?: string;
    capitalize?: boolean;
    showEmptyDropdown?: boolean;
};

export const testIds = { tooltip: "tooltip", message: "message" };

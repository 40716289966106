import React, { useMemo, useState } from "react";

import { testIds, TooltipMode, TooltipProps } from "./tooltip.interface";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { useIsMounted } from "lib/hooks/use-is-mounted.hook";
import { zIndexes } from "lib/z-indexes";
import {
    StyledButton,
    StyledPopover,
    StyledPopoverDropdown,
    StyledPopoverTarget,
} from "./tooltip.styled";

export const Tooltip = ({
    message,
    position,
    svgComponent = <Icon name={Icons.infoSvg} />,
    mode = TooltipMode.HOVER,
    testId = testIds.tooltip,
    capitalize = false,
    showEmptyDropdown = true,
}: TooltipProps) => {
    const [opened, setOpened] = useState(false);

    const isMounted = useIsMounted();

    const modeProps = useMemo(
        () =>
            mode === "click"
                ? { onClick: () => setOpened((prev) => !prev) }
                : {
                      onMouseEnter: () => setOpened(true),
                      onMouseLeave: () => setOpened(false),
                  },
        [mode],
    );

    if (!isMounted) {
        return null;
    }

    return (
        <StyledPopover
            shadow="md"
            position={position}
            zIndex={zIndexes.tooltip}
            opened={opened}
            data-testid={testId}
        >
            <StyledPopoverTarget>
                <StyledButton type="button" {...modeProps}>
                    {svgComponent}
                </StyledButton>
            </StyledPopoverTarget>
            {(!!message || showEmptyDropdown) && (
                <StyledPopoverDropdown
                    capitalize={capitalize ? "capitalize" : "none"}
                    data-testid={`${testId}-${testIds.message}`}
                >
                    {message}
                </StyledPopoverDropdown>
            )}
        </StyledPopover>
    );
};
